import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export default function HidiProductComponent(props) {
  const {
    imgUrl,
    title,
    price,
    beforePrice,
    productNum,
    sampleUrl,
    thankProductNum,
  } = props;
  return (
    <div style={{ padding: "20px 15px 20px" }}>
      <div style={{ borderRadius: 5 }}>
        <Paper elevation={3}>
          <div style={{ padding: 20, borderBottom: "1px solid #dadada" }}>
            {/* <Link
              to={
                productNum
                  ? `/hidiOrder?num=${productNum}`
                  : `/torder?num=${thankProductNum}`
              }
            >
              <img src={imgUrl} style={{ width: "100%" }} />
            </Link> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              padding: "5px 10px",
              paddingBottom: 15,
            }}
          >
            <span style={{ fontSize: 18, fontWeight: "bold" }}>{title}</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                lineHeight: "16px",
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  color: "#999",
                  textDecoration: "line-through",
                }}
              >
                {beforePrice}
              </span>
              <span
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "#e2232b",
                  paddingBottom: 3,
                }}
              >
                {price}
              </span>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <a
              style={{ flex: 1, textDecoration: "none" }}
              href={sampleUrl}
              target="_blank"
            >
              <Button
                style={{
                  width: "100%",
                  height: 50,
                  borderRadius: 0,
                  backgroundColor: "#FFC837",
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottomLeftRadius: 5,
                }}
              >
                샘플보기
              </Button>
            </a>
            {/* <Link
              to={
                productNum
                  ? `/hidiOrder?num=${productNum}`
                  : `/torder?num=${thankProductNum}`
              }
              style={{ flex: 1, textDecoration: "none" }}
            >
              <Button
                style={{
                  height: 50,
                  width: "100%",
                  backgroundColor: "#F76C6C",
                  color: "#fff",
                  borderRadius: 0,
                  fontWeight: "bold",
                  borderBottomRightRadius: 5,
                }}
              >
                제작하기
              </Button>
            </Link> */}
          </div>
        </Paper>
      </div>
    </div>
  );
}
