import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import NewLoginScreen from "../screens/LoginScreen/NewLoginScreen";
import MainScreen from "../screens/MainScreen/MainScreen";
import OrderScreen from "../screens/OrderScreen/OrderScreen";
import ThanksOrderScreen from "../screens/ThanksOrderScreen/ThanksOrderScreen";
import CallbackPage from "../screens/LoginScreen/CallbackPage";
import WeddingLocationScreen from "../screens/OrderScreen/WeddingLocationScreen/WeddingLocationScreen";
import HusbandScreen from "../screens/OrderScreen/HusbandScreen/HusbandScreen";
import WifeScreen from "../screens/OrderScreen/WifeScreen/WifeScreen";
import GreetingScreen from "../screens/OrderScreen/GreetingScreen/GreetingScreen";
import ImageUploadScreen from "../screens/OrderScreen/ImageUploadScreen/ImageUploadScreen";
import CompleteScreen from "../screens/OrderScreen/CompleteScreen/CompleteScreen";
import ListScreen from "../screens/ListScreen/ListScreen";
import ModifyScreen from "../screens/ModifyScreen/ModifyScreen";
import ThanksModifyScreen from "../screens/ThanksModifyScreen/ThanksModifyScreen";
import OrderlistScreen from "../screens/OrderlistScreen/OrderlistScreen";
import ThanksOrderlistScreen from "../screens/ThanksOrderlistScreen/ThanksOrderlistScreen";
import CouponScreen from "../screens/CouponScreen/CouponScreen";

import HidiOrderScreen from "../screens/HidiOrderScreen/HidiOrderScreen";
import HidiOrderListScreen from "../screens/HidiOrderListScreen/HidiOrderListScreen";
import HidiListScreen from "../screens/HidiListScreen/HidiListScreen";
import HidiLoginScreen from "../screens/LoginScreen/HidiLoginScreen";
import HidiModifyScreen from "../screens/HidiModifyScreen/HidiModifyScreen";
import SurveyScreen from "../screens/SurveyScreen/SurveyScreen";
import SurveyDetailsScreen from "../screens/SurveyDetailsScreen/SurveyDetailsScreen";
import ProductionScreen from "../screens/ProductionScreen/ProductionScreen";
import EventScreen from "../screens/EventScreen/EventScreen";
import NewCouponScreen from "../screens/NewCouponScreen/NewCouponScreen";
import NewMainScreen from "../screens/NewMainScreen/NewMainScreen";
import NewEventScreen from "../screens/NewEventScreen/NewEventScreen";
import QuestionScreen from "../screens/QuestionScreen/QuestionScreen";
import MyPageScreen from "../screens/MyPageScreen/MyPageScreen";
import PathScreen from "../screens/PathScreen/PathScreen";
import LogoutScreen from "../screens/LogoutScreen/LogoutScreen";
import NewSurveyScreen from "../screens/NewSurveyScreen/NewSurveyScreen";
import NewSurveyDetailsScreen from "../screens/NewSurveyDetailsScreen/NewSurveyDetailsScreen";
import FirstProduction from "../screens/FirstProduction/FirstProduction";
import ExampleScreen from "../screens/ExampleScreen/ExampleScreen";
import TestScreen from "../screens/TestScreen/TestScreen";
import PricingScreen from "../screens/PricingScreen/PricingScreen";
import InfoScreen from "../screens/InfoScreen/InfoScreen";
import CardQrScreen from "../screens/CardQrScreeen/CardQrScreen";
import WeddingCardScreen from "../screens/WeddingCardScreen/WeddingCardScreen";

const AppRouter = ({ isLoggedIn, props }) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <MainScreen />
        </Route>

        <Route exact path="/new">
          <MainScreen />
        </Route>

        <Route exact path="/event/1">
          <NewEventScreen />
        </Route>

        <Route exact path="/question">
          <QuestionScreen />
        </Route>

        <Route exact path="/mypage">
          <MyPageScreen />
        </Route>

        <Route exact path="/order">
          <OrderScreen />
        </Route>

        <Route exact path="/torder">
          <ThanksOrderScreen />
        </Route>

        <Route exact path="/order/location">
          <WeddingLocationScreen />
        </Route>

        <Route exact path="/order/husband">
          <HusbandScreen />
        </Route>

        <Route exact path="/order/wife">
          <WifeScreen />
        </Route>

        <Route exact path="/order/greeting">
          <GreetingScreen />
        </Route>

        <Route exact path="/order/image">
          <ImageUploadScreen />
        </Route>

        <Route exact path="/order/complete">
          <CompleteScreen />
        </Route>

        <Route exact path="/login">
          <LoginScreen />
        </Route>

        <Route exact path="/list">
          <ListScreen />
        </Route>

        <Route exact path="/modify/:letterId">
          <ModifyScreen />
        </Route>

        {/* <Route exact path="/tmodify/:letterId">
          <ThanksModifyScreen />
        </Route> */}

        <Route exact path="/orderlist">
          <OrderlistScreen />
        </Route>

        {/* <Route exact path="/torderlist">
          <ThanksOrderlistScreen />
        </Route> */}

        <Route exact path="/coupon0530">
          <CouponScreen />
        </Route>

        {/* 하이디 */}
        <Route exact path="/hidi">
          <HidiOrderListScreen />
        </Route>

        {/* <Route exact path="/hidiOrder">
          <HidiOrderScreen />
        </Route> */}

        <Route exact path="/hidiList">
          <HidiListScreen />
        </Route>

        <Route exact path="/hidiLogin">
          <HidiLoginScreen />
        </Route>

        {/* <Route exact path="/hidiModify/:letterId">
          <HidiModifyScreen />
        </Route> */}

        {/* 하이디 */}

        <Route exact path="/survey/:letterId">
          <SurveyScreen />
        </Route>

        <Route exact path="/survey/details/:letterId">
          <SurveyDetailsScreen />
        </Route>

        <Route exact path="/Api/Member/Oauth2ClientCallback/naver">
          <CallbackPage />
        </Route>

        <Route exact path="/Api/Member/Oauth2ClientCallback/naver2">
          <CallbackPage path="old" />
        </Route>

        <Route exact path="/Api/Member/Oauth2ClientCallback/naver3">
          <CallbackPage path="hidi" />
        </Route>

        {/* <Route exact path="/product">
                    <ProductionScreen />
                </Route> */}

        <Route exact path="/product/:letterId">
          <ProductionScreen />
        </Route>

        <Route exact path="/event">
          <EventScreen />
        </Route>

        <Route exact path="/ccppnn">
          <NewCouponScreen />
        </Route>

        <Route exact path="/path">
          <PathScreen />
        </Route>

        <Route exact path="/logout">
          <LogoutScreen />
        </Route>

        <Route exact path="/newlogin">
          <NewLoginScreen />
        </Route>

        <Route exact path="/newsurvey/:letterId">
          <NewSurveyScreen />
        </Route>

        <Route exact path="/newsurvey/details/:letterId">
          <NewSurveyDetailsScreen />
        </Route>

        <Route exact path="/first">
          <FirstProduction />
        </Route>

        <Route exact path="/example">
          <ExampleScreen />
        </Route>

        <Route exact path="/price">
          <PricingScreen />
        </Route>

        <Route exact path="/test">
          <TestScreen />
        </Route>

        <Route exact path="/info">
          <InfoScreen />
        </Route>

        <Route exact path="/card/qr">
          <CardQrScreen />
        </Route>

        <Route exact path="/weddingcard">
          <WeddingCardScreen />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
